import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import Auth from "../../Auth/Auth";
import { Toast } from "../../shared/toaster/Toaster";
import { post, get, remove, handleError } from "../../shared/HttpReq";
// import { useForm } from "../../shared/useForm";
import Spinner from "../../shared/spinner/Spinner";

import appStr from "../../assets/img/app-str.svg";
import playStr from "../../assets/img/play-str.svg";
// import girlPlay from "../../assets/img/girl-ply.png";
import trngRt from "../../assets/img/trng-rt.png";
import msnVdo from "../../assets/img/msn-vdo.png";
import play from "../../assets/img/play.svg";
import protection from "../../assets/img/protection.svg";
import logo from "../../assets/img/logo.svg";
import downArrow from "../../assets/img/down-arrow.png";
import fitness from "../../assets/img/fitness.svg";
import integrate from "../../assets/img/integrate.svg";

import hannah from "../../assets/img/hannah-testi.jpg";
import markTesti from "../../assets/img/mark-testi.jpg";
import blkCmnt from "../../assets/img/blk-cmnt.svg";
import danielTesti from "../../assets/img/daniel-testi.jpg";
import drewTesti from "../../assets/img/drew-testi.jpg";
import charlesTesti from "../../assets/img/charles-testi.jpg";
import testi from "../../assets/img/testi.svg";
import jillTesti from "../../assets/img/jill-testi.jpg";
import barlowTesti from "../../assets/img/barlow-testi.jpeg";

import leftArrow from "../../assets/img/left.svg";
import rightArrow from "../../assets/img/right.svg";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Footer from "../../shared/footer/Fotter";

import Modal from "react-bootstrap/Modal";
import StripeForm from "./stripe";
import Carousel from "react-bootstrap/Carousel";

import Banner1 from "../../assets/img/Banner1.png";
import Banner2 from "../../assets/img/Banner2.jpg";
import CampsIMG from "../../assets/img/CampsSec.png";

import Header from "../../shared/header/Header";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
import guess from "moment-timezone";
import { loadStripe } from "@stripe/stripe-js";
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { Button } from "react-bootstrap";

function Dashboard(props) {
  const [show, setShow] = useState(false);
  const [showStripe, setShowStripe] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showAlreadyPurchase, setShowAlreadyPurchase] = useState(false);
  const [showProUser, setshowProUser] = useState(false);
  const pro_access = localStorage.getItem("pro_access");
  const [disableBtn, updateDisableBtn] = useState(false);

  // TOASTER STATE
  const [toaster, SetToaster] = useState({
    show: false,
    type: "", // type should be "danger" or "success"
    content: "",
  });

  const route = useHistory();
  const [isLoading, setLoading] = useState(false);

  const [navText, setNavText] = useState([
    `<img src=${leftArrow} /> `,
    `<img src=${rightArrow} />`,
  ]);

  const [responsive, updateResponsive] = useState({
    0: {
      items: 1,
      margin: 25,
      stagePadding: 100,
    },
    600: {
      items: 1,
      margin: 40,
      stagePadding: 150,
    },
    768: {
      items: 1,
      margin: 50,
      stagePadding: 175,
    },

    1120: {
      items: 1,
      margin: 60,
      stagePadding: 200,
    },
    1168: {
      items: 1,
      margin: 75,
      stagePadding: 250,
    },
    1500: {
      items: 1,
      margin: 85,
      stagePadding: 400,
    },
    1716: {
      items: 1,
      margin: 95,
      stagePadding: 450,
    },
    1799: {
      items: 1,
      margin: 100,
      stagePadding: 475,
    },
    1920: {
      items: 1,
      margin: 110,
      stagePadding: 500,
    },
  });

  const stripePromise = loadStripe(process.env.REACT_APP_StripePromise);

  const [subscriptionstartDate, setSubscriptionstartDate] = useState("");
  const [subscriptionendDate, setSubscriptionendDate] = useState("");
  // eslint-disable-next-line
  useEffect(async () => {
    const search = props.location.search;
    const name = new URLSearchParams(search).get("payment");

    if (Auth.isUserAuthenticated()) {
      const user3 = await getUserSubscriptionStatus();

      if (user3.is_membership === 1) {
        // here we are fetching start and end date
        let res = await handleError(
          await fetch(
            `${process.env.REACT_APP_BASE_URL}/user/membership`,
            await get()
          )
        );
        if (res.status === 200) {
          const stdate = res.data.start_date;
          const endate = res.data.end_date;
          const startDate = moment(stdate * 1000).format("DD-MM-YYYY");
          setSubscriptionstartDate(startDate);
          const endDate = moment(endate * 1000).format("DD-MM-YYYY");
          setSubscriptionendDate(endDate);
          if (res.data.amount_paid === 0) {
            setShow(true);
          } else {
            setShowAlreadyPurchase(true);
          }
          if (res.data.is_canceled === 0) {
          } else if (res.data.is_canceled === 1) {
            updateDisableBtn(true);
          }

          return res.data;
        } else {
          SetToaster({
            show: true,
            type: "danger", // type should be "danger" or "success"
            content: res.message,
          });
          setTimeout(() => {
            SetToaster({
              show: false,
              type: "",
              content: "",
            });
          }, 3000);
        }
        // end fetching date and time
        return;
      } else if (JSON.parse(localStorage.getItem("pro_access")) === 1) {
        setshowProUser(true);
        setShow(false);
      } else {
        setShow(true);
      }
    } else {
      route.push("/");
    }
  }, []);

  // check User Subcription  and membership Status
  const getUserSubscriptionStatus = async () => {
    setLoading(true);
    let res = await handleError(
      await fetch(
        `${process.env.REACT_APP_BASE_URL}/subscription-status`,
        await get()
      )
    );
    if (res.status === 200) {
      setLoading(false);
      return res.data;
    } else {
      SetToaster({
        show: true,
        type: "danger", // type should be "danger" or "success"
        content: res.message,
      });
      setTimeout(() => {
        SetToaster({
          show: false,
          type: "",
          content: "",
        });
      }, 3000);
    }

    setLoading(false);
  };

  const openModal = async (e, type) => {
    e.preventDefault();
    const user3 = await getUserSubscriptionStatus();
    const user = Auth.user();

    if (user3.is_membership === 1) {
      let res = await handleError(
        await fetch(
          `${process.env.REACT_APP_BASE_URL}/user/membership`,
          await get()
        )
      );
      if (res.status === 200) {
        const stdate = res.data.start_date;
        const endate = res.data.end_date;
        const startDate = moment(stdate * 1000).format("DD-MM-YYYY");
        setSubscriptionstartDate(startDate);
        const endDate = moment(endate * 1000).format("DD-MM-YYYY");
        setSubscriptionendDate(endDate);
        if (res.data.amount_paid === 0) {
          setShow(true);
        } else {
          setShowAlreadyPurchase(true);
        }
      }
      // setShowAlreadyPurchase(true);
      return;
    } else if (JSON.parse(localStorage.getItem("pro_access")) === 1) {
      setshowProUser(true);
      setShow(false);
    } else {
      if (type === "stripe") {
        setShow(false);
        setShowStripe(true);
        setShowSuccessModal(false);
        return;
      }
      setShow(true);
    }
  };

  // const signupUser = async (e) => {
  //     e.preventDefault();

  //     setLoading(true);
  //     // const signupData = { ...values, ...{ deviceType: 'web', deviceToken: '', accept_term: 1 } };
  //     let res = await handleError(await fetch(`${process.env.REACT_APP_BASE_URL}/auth/registration`, await post({})));
  //     if (res.status === 200) {
  //         Auth.login(res);
  //         SetToaster({
  //             show: true,
  //             type: "danger", // type should be "danger" or "success"
  //             content: res.message,
  //         })
  //         setTimeout(() => {
  //             SetToaster({
  //                 show: false,
  //                 type: "",
  //                 content: "",
  //             });
  //             route.push('/dashboard');
  //         }, 1500);

  //     }
  //     else if (res.status === 422) {
  //         console.log('error:', res);

  //         SetToaster({
  //             show: true,
  //             type: "danger", // type should be "danger" or "success"
  //             content: res.message,
  //         })

  //         setTimeout(() => {
  //             SetToaster({
  //                 show: false,
  //                 type: "",
  //                 content: "",
  //             })
  //         }, 3000);
  //     }
  //     else {
  //         SetToaster({
  //             show: true,
  //             type: "danger", // type should be "danger" or "success"
  //             content: res.message,
  //         });
  //         setTimeout(() => {
  //             SetToaster({
  //                 show: false,
  //                 type: "",
  //                 content: "",
  //             });
  //         }, 3000);
  //     }

  //     setLoading(false);

  // }

  // promo1 code working

  const [fixedprice, setfixedprice] = useState(99.99);
  const [promeMessage, setPromeMessage] = useState(false);
  const [promoCode, setpromoCode] = useState("");
  const convertedPromo = promoCode.toUpperCase();

  const [coupntye, setcoupntye] = useState("");

  // let timeString = guess().format();
  let timezone = guess?.tz?.guess();
  let completeTime = timezone;

  // console.log("completeTime", completeTime);

  const applypromocode = async (e) => {
    e.preventDefault();
    if (promoCode === "") {
      toast.error("Enter Valid Promocode", 1000);
    } else {
      let res = await handleError(
        await fetch(
          `${process.env.REACT_APP_BASE_URL}/user/membership-coupon?coupon_code=${convertedPromo}&time_zone=${completeTime}`,
          await get()
        )
      );
      if (res.status === 200) {
        toast.success("Promo Applied successfull");
        const FixPrice = 99.99;
        const discount_val = res.data.discount_value;
        const actual_price = (FixPrice / 100) * discount_val;
        const newprice = FixPrice - actual_price;
        const roundFig_newprice = newprice.toFixed(2);
        setfixedprice(roundFig_newprice);
        setPromeMessage(true);

        const copn_type = res.data.coupon_type;
        setcoupntye(copn_type);

        if (copn_type === "free") {
          localStorage.setItem("pro_access", 1);
          setshowProUser(true);
          setShow(false);
        }

        return res.data;
      } else {
        toast.error("Promocode Expired or Invalid", 1000);
        setpromoCode("");
        setfixedprice(99.99);
        setPromeMessage(false);
        // SetToaster({
        //     show: true,
        //     type: "danger", // type should be "danger" or "success"
        //     content: res.message,

        // });
        // setTimeout(() => {
        //     SetToaster({
        //         show: false,
        //         type: "",
        //         content: "",
        //     });
        // }, 3000);
      }
    }
  };

  // cancel member ship

  const cancelMembership = async (e) => {
    e.preventDefault();
    if (window.confirm("Do you want Cancel Membership for Next Year?")) {
      let res = await handleError(
        await fetch(
          `${process.env.REACT_APP_BASE_URL}/user/membership`,
          await remove()
        )
      );
      if (res.status === 200) {
        updateDisableBtn(true);
        toast.success("MemberShip canceled Sucessfully", 1000);
        return res.data;
      } else {
        SetToaster({
          show: true,
          type: "danger", // type should be "danger" or "success"
          content: res.message,
        });
        setTimeout(() => {
          SetToaster({
            show: false,
            type: "",
            content: "",
          });
        }, 3000);
      }
    }
  };

  return (
    <>
      {isLoading ? <Spinner /> : null}
      <Toast
        show={toaster.show}
        content={toaster.content}
        type={toaster.type}
        onClose={() => {
          SetToaster({
            show: false,
            type: "",
            content: "",
          });
        }}
      />
      {/* <header className="header">
                <div className="hd-regs">
                    <button className="btn btn-regs" onClick={userLogout}>Logout <i className="fa fa-angle-right"></i></button>
                </div>
            </header> */}
      <section className="sec-1">
        <div className="container-fluid px-0">
          <div className="homesec">
            <Header />
            <Carousel>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={Banner1}
                  alt="First slide"
                />

                <Carousel.Caption>
                  {/* <h3>First slide label</h3>
								<p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> */}
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div className="sec1-lt">
                      <h2>
                        A Way To Train,
                        <br />
                        To Play &amp; To Live
                      </h2>
                      <button
                        className="btn btn-member"
                        onClick={(e) => openModal(e, "signup")}
                      >
                        Membership <i className="fa fa-angle-right"></i>
                      </button>
                      <div className="app-dwld-btns">
                        <a
                          href="https://apps.apple.com/in/app/winning-ways-training/id1552675237"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {" "}
                          <img src={appStr} alt="" className="img-fluid" />{" "}
                        </a>
                        <a
                          href="https://play.google.com/store/apps/details?id=com.oit.wways&hl=en"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {" "}
                          <img
                            src={playStr}
                            alt=""
                            className="img-fluid img-ply-str"
                          />{" "}
                        </a>
                      </div>
                    </div>
                  </div>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={Banner2}
                  alt="Second slide"
                />

                <Carousel.Caption>
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div className="sec1-lt">
                      <h2>
                        A Way To Train, <br />
                        To Play & To Live
                      </h2>
                      <button
                        className="btn btn-member"
                        onClick={(e) => openModal(e, "signup")}
                      >
                        Membership <i className="fa fa-angle-right"></i>
                      </button>
                      <div className="app-dwld-btns">
                        <a
                          href="https://apps.apple.com/in/app/winning-ways-training/id1552675237"
                          rel="noreferrer"
                          target="_blank"
                        >
                          {" "}
                          <img src={appStr} alt="" className="img-fluid" />{" "}
                        </a>
                        <a
                          href="https://play.google.com/store/apps/details?id=com.oit.wways&hl=en"
                          rel="noreferrer"
                          target="_blank"
                        >
                          {" "}
                          <img
                            src={playStr}
                            alt=""
                            className="img-fluid img-ply-str"
                          />{" "}
                        </a>
                      </div>
                    </div>
                  </div>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={Banner1}
                  alt="Third slide"
                />

                <Carousel.Caption>
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div className="sec1-lt">
                      <h2>
                        A Way To Train,
                        <br /> To Play & To Live
                      </h2>
                      <button
                        className="btn btn-member"
                        onClick={(e) => openModal(e, "signup")}
                      >
                        Membership <i className="fa fa-angle-right"></i>
                      </button>
                      <div className="app-dwld-btns">
                        <a
                          href="https://apps.apple.com/in/app/winning-ways-training/id1552675237"
                          rel="noreferrer"
                          target="_blank"
                        >
                          {" "}
                          <img src={appStr} alt="" className="img-fluid" />{" "}
                        </a>
                        <a
                          href="https://play.google.com/store/apps/details?id=com.oit.wways&hl=en"
                          rel="noreferrer"
                          target="_blank"
                        >
                          {" "}
                          <img
                            src={playStr}
                            alt=""
                            className="img-fluid img-ply-str"
                          />{" "}
                        </a>
                      </div>
                    </div>
                  </div>
                </Carousel.Caption>
              </Carousel.Item>
            </Carousel>

            {/* <div className="d-flex justify-content-start hd-regs">
							<button className="btn btn-member registerBtn " onClick={(e) => openModal(e, 'signup')}>Register <i className="fa fa-angle-right"></i></button>
						</div> */}

            <div className="row">
              {/* <img src={SliderIMG1} alt="" /> */}
              {/* <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
							<div className="sec1-lt">
								<h2>A Way To Train, To Play & To Live</h2>
								<button className="btn btn-member" onClick={(e) => openModal(e, 'signup')}>Subscribe Membership <i className="fa fa-angle-right"></i></button>
								<div className="app-dwld-btns">
									<a href="#" target="_blank"> <img src={appStr} alt="" className="img-fluid" /> </a>
									<a href="#" target="_blank"> <img src={playStr} alt="" className="img-fluid img-ply-str" /> </a>
								</div>
							</div>
						</div> */}
              {/* <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
							<div className="sec1-rt"> <img src={SliderIMG1} alt="" className="img-fluid" /> </div>
						</div> */}
            </div>
          </div>
        </div>
      </section>

      <section className="trng">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12">
              <div className="trng-rt">
                {" "}
                <img src={trngRt} alt="" className="img-fluid trng-img" />{" "}
              </div>
            </div>

            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12">
              <div className="trng-lt">
                <h3 className="sec-hd">
                  TRAIN PLAY
                  <br /> LIVE Membership
                </h3>
                <p className="cnt">
                  Sign up to gain full access to our Core 90 Curriculum and
                  Session Plans, for one year. With a proven method that has
                  supported the growth and advancement of club, college, and pro
                  players, we’re excited to share the Winning Ways program to a
                  larger audience on a mobile platform. Start your journey with
                  us today!”
                </p>
                {/* <div className="trng-ofr">
									<p className="offer"><del>$249</del> <span className="new-prc">$99</span> for 6 months subscription</p>
								</div> */}
                <button
                  className="btn btn-member"
                  onClick={(e) => openModal(e, "signup")}
                >
                  Membership <i className="fa fa-angle-right"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!--Training Program section end-->
	<!--Mission section start--> */}
      <section className="msn">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12"></div>
            <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
              <h3 className="sec-hd">Our Mission</h3>
              <p className="cnt">
                Winning Ways Training provides total technical training and
                complete athletic development; equipping players to transform
                their game and transform themselves.
              </p>
              {/* <!--Carousel start--> */}
              <div
                id="carouselIndicators"
                className="carousel slide"
                data-ride="carousel"
              >
                <ol className="carousel-indicators">
                  <li
                    data-target="#carouselIndicators"
                    data-slide-to="0"
                    className="active"
                  ></li>
                  <li data-target="#carouselIndicators" data-slide-to="1"></li>
                  <li data-target="#carouselIndicators" data-slide-to="2"></li>
                </ol>
                <div className="carousel-inner">
                  <div className="carousel-item active">
                    {" "}
                    <img
                      className="d-block w-100 img-fluid msn-vdo"
                      src={msnVdo}
                      alt=""
                    />{" "}
                    <img
                      className="img-fluid img-play cursor-pointer"
                      src={play}
                      alt=""
                    />{" "}
                  </div>
                  <div className="carousel-item">
                    {" "}
                    <img
                      className="d-block w-100 img-fluid msn-vdo"
                      src={msnVdo}
                      alt=""
                    />{" "}
                    <img className="img-fluid img-play" src={play} alt="" />{" "}
                  </div>
                  <div className="carousel-item">
                    {" "}
                    <img
                      className="d-block w-100 img-fluid msn-vdo"
                      src={msnVdo}
                      alt=""
                    />{" "}
                    <img className="img-fluid img-play" src={play} alt="" />{" "}
                  </div>
                  <div style={{ padding: "56.25% 0 0", position: "relative" }}>
                    <iframe
                      src="https://player.vimeo.com/video/410653885?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                      frameborder="0"
                      allow="autoplay; fullscreen; picture-in-picture"
                      allowfullscreen
                      style={{
                        position: "absolute",
                        top: "0",
                        left: "0",
                        width: "100%",
                        height: "100%",
                      }}
                      title="Winning Ways - Train to Play | Play to Win"
                    ></iframe>
                  </div>
                </div>
                <Link
                  to="#"
                  className="carousel-control-prev"
                  role="button"
                  data-slide="prev"
                >
                  {" "}
                  <span
                    className="carousel-control-prev-icon"
                    aria-hidden="true"
                  ></span>{" "}
                  <span className="sr-only">Previous</span>{" "}
                </Link>
                <Link
                  to="#"
                  className="carousel-control-next"
                  role="button"
                  data-slide="next"
                >
                  {" "}
                  <span
                    className="carousel-control-next-icon"
                    aria-hidden="true"
                  ></span>{" "}
                  <span className="sr-only">Next</span>{" "}
                </Link>
              </div>
              {/* <!--Carousel end--> */}
            </div>
            <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12"></div>
          </div>
        </div>
      </section>
      {/* <!--Mission section end-->
	<!--Methods section start--> */}
      <div className="mthds">
        <div className="hd">
          <h3 className="sec-hd text-center">Our Methods</h3>
        </div>
        <div className="all-methods text-center">
          <div className="container-fluid">
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12 crcl-img">
                <div className="circle-img">
                  <img className="" src={protection} alt="" />
                </div>
                {/* <!-- <img className="img-fluid img-crcl" src="assets/img/blue-crcl1.svg" alt=""> --> */}

                <h4>Proven</h4>
                <p className="cnt">Technical Curriculum</p>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12 crcl-img">
                <div className="circle-img">
                  <img className="" src={fitness} alt="" />
                </div>
                <h4>Holistic</h4>
                <p className="cnt">Physical Training</p>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12 crcl-img">
                <div className="circle-img">
                  <img className="" src={integrate} alt="" />
                </div>
                <h4>Integrated</h4>
                <p className="cnt">Synthesis</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!--Methods section start-->
	<!--Membership section start--> */}
      <section className="membrshp text-center">
        <h3 className="sec-hd">Membership</h3>
        <p className="cnt">
          Become a member today to gain exclusive access to our training video
          library.
        </p>
        <button
          className="btn btn-member"
          onClick={(e) => openModal(e, "signup")}
        >
          Membership <i className="fa fa-angle-right"></i>
        </button>
      </section>
      {/* <!--Membership section end-->
          

            {/* <!--Traing sample section end-->
    <!--Testimonial section start--> */}

      <section className="trng-smpl">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12">
              <div className="trng-smpl-lt">
                <h3 className="sec-hd">Camps</h3>
                <p className="cnt">
                  Check our camp offerings or send us an email to set up in
                  person training for your team or club.
                </p>
                <div className="d-flex justify-content-end hd-regs">
                  {/* <button className="btn btn-member" onClick={() => window.open("https://winningwaystraining.com/Winningways/product-category/camps/")}>Click Here <i className="fa fa-angle-right"></i></button> */}
                  {/* <button className="btn btn-member">Click Here <i className="fa fa-angle-right"></i></button> */}
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12">
              <div className="trng-smpl-rt text-center">
                <img
                  className="d-block w-100 img-fluid msn-vdo "
                  src={CampsIMG}
                  alt=""
                />
                {/* <div style={{ padding: '56.25% 0 0', position: 'relative' }}><iframe src="https://player.vimeo.com/video/408285850?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%' }} title="Advanced Open Field Dribbling + Core &amp;amp; Joint | Phase 1"></iframe></div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!--Traing sample section end--> */}

      <section className="testimony">
        <div className="hd">
          <h3 className="sec-hd text-center">Testimonials</h3>
        </div>
        <div className="row">
          <OwlCarousel
            className="owl-theme"
            loop
            margin={30}
            items={3}
            nav
            responsive={responsive}
            stagePadding={50}
            dots={false}
            navText={navText}
          >
            <div className="item" itemElement>
              {/* <div className="owl-item ">
               */}
              <div className="">
                <div className="testi-box">
                  <div className="imgpic">
                    <img className="img-fluid" src={hannah} alt="" />
                  </div>
                  <div className="img-cmnt">
                    <img
                      src={blkCmnt}
                      className="img-fluid img-testi"
                      alt="winning ways"
                    />
                  </div>
                  <div className="testi-cnt">
                    <p>
                      Winning Ways has created a unique soccer and strength
                      curriculum that has changed my game and off-season
                      preparation. Adding the program into my weekly training
                      has dramatically improved my technical abilities while
                      unlocking creativity and a new found confidence on the
                      ball I’ve never experienced as a life-long defender. The
                      simplicity of the Winning Ways setup allows me to train
                      anytime, anywhere, even on my busiest days. I highly
                      recommend this program for anyone that is ready to take
                      their game to the next level. When you master the
                      fundamentals, the potential is limitless!
                    </p>
                    <h3>Hannah Davison</h3>
                    <span>
                      AIK Football Damer, Chicago Red Stars, Northwestern
                      University
                    </span>
                  </div>
                </div>
              </div>
              {/*
					</div> */}
            </div>
            <div className="item">
              {/* <div className="owl-item ">
               */}
              <div className="">
                <div className="testi-box">
                  <div className="imgpic">
                    <img className="img-fluid" src={markTesti} alt="" />
                  </div>
                  <div className="img-cmnt">
                    <img
                      src={blkCmnt}
                      className="img-fluid img-testi"
                      alt="winning ways"
                    />
                  </div>

                  <div className="testi-cnt">
                    <p>
                      Winning Ways Training was exactly the training I needed in
                      order to take that next step in my development as a
                      player. The unique full body focus helped me handle going
                      up against bigger, stronger players not only in college
                      but as professional as well. On top of that, the soccer
                      specific curriculum keeps you sharp and fit during the off
                      season so you don’t miss a beat going into preseason. It’s
                      an overall top level program that all players who are
                      serious about continually improving should experience!
                    </p>
                    <h3>Mark Segbers</h3>
                    <span>New England Revolution, Memphis 901</span>
                  </div>
                </div>
              </div>
              {/*</div> */}
            </div>
            <div className="item">
              {/* <div className="owl-item cloned"> */}
              <div className="">
                <div className="testi-box">
                  <div className="imgpic">
                    <img className="img-fluid" src={danielTesti} alt="" />
                  </div>
                  <div className="img-cmnt">
                    <img
                      src={blkCmnt}
                      className="img-fluid img-testi"
                      alt="winning ways"
                    />
                  </div>

                  <div className="testi-cnt">
                    <p>
                      Winning Ways is unlike anything I’ve experienced. As a
                      player who prides himself on having unique technical
                      ability, this program brought me back to foundational
                      elements that I had moved so far past, it was as if I had
                      lost them. Regaining and then building upon these elements
                      revealed that I could refine every aspect of my game, and
                      still improve in an area where I felt I'd hit a ceiling.
                      As much as people would love to advertise that they do
                      this type of all encompassing training, they just don’t,
                      I’ve been in the most elite of environments and Winning
                      Ways fills in cracks that are missing even there. It’s
                      truly unique, it’s truly a necessity for players of every
                      level!
                    </p>
                    <h3>Daniel Johnson</h3>
                    <span>West Ham United, Louisville, Chicago Fire</span>
                  </div>
                </div>
              </div>
              {/*</div> */}
            </div>
            <div className="item">
              {/* <div className="owl-item cloned"> */}
              <div className="">
                <div className="testi-box">
                  <div className="imgpic">
                    <img className="img-fluid" src={drewTesti} alt="" />
                  </div>
                  <div className="img-cmnt">
                    <img
                      src={blkCmnt}
                      className="img-fluid img-testi"
                      alt="winning ways"
                    />
                  </div>

                  <div className="testi-cnt">
                    <p>
                      When you find something that works, you go back to it. The
                      Winning Ways program is something I’ll continue to go back
                      to throughout my playing career to refine my skills.
                      Paired with its top notch strength and conditioning
                      program, this is the perfect workout for any soccer player
                      who’s looking to take their game to the next level.{" "}
                    </p>
                    <h3>Drew Conner</h3>
                    <span>Wisconsin, Chicago Fire, Indy Eleven</span>
                  </div>
                </div>
              </div>
              {/* </div> */}
            </div>
            <div className="item">
              {/* <div className="owl-item cloned"> */}
              <div className="">
                <div className="testi-box">
                  <div className="imgpic">
                    <img className="img-fluid" src={charlesTesti} alt="" />
                  </div>
                  <div className="img-cmnt">
                    <img
                      src={blkCmnt}
                      className="img-fluid img-testi"
                      alt="winning ways"
                    />
                  </div>

                  <div className="testi-cnt">
                    <p>
                      Winning Ways helped carry me to where I am today. First as
                      an individual and secondly as a soccer player/coach. I
                      began working with the program when I was a teenager
                      playing club and high school soccer in the Milwaukee and
                      Madison areas. The focus of the curriculum, and wholistic
                      instruction, helped me to become a professional player and
                      more importantly taught me the foundation of how to become
                      a better person. I worked directly with Rob, whose focus
                      and determination during training sessions exemplified
                      what Winning Ways is all about. This program will help
                      every individual, in any sport or profession. It set me on
                      a path that led to a fifteen year career in the Dutch
                      Premier and First Division, and now UEFA A Coaching
                      License.
                    </p>
                    <h3>Charles Kaslauskas</h3>
                    <span> NEC Neijmegin </span>
                  </div>
                </div>
              </div>
              {/* </div> */}
            </div>

            <div className="item">
              {/* <div className="owl-item cloned"> */}
              <div className="">
                <div className="testi-box">
                  <div className="imgpic">
                    <img className="img-fluid" src={jillTesti} alt="" />
                  </div>
                  <div className="img-cmnt">
                    <img
                      src={blkCmnt}
                      className="img-fluid img-testi"
                      alt="winning ways"
                    />
                  </div>

                  <div className="testi-cnt">
                    <p>
                      Winning Ways is a must for players who are looking to
                      develop their technical skills and foot speed, but also
                      their competitive edge through positive reinforcement and
                      discipline. The program and coaches create a great
                      environment for players to develop their skills and take
                      risks. I was involved Winning Ways for four years and
                      there is no doubt that my level of comfort on the ball
                      improved along with my confidence in how I approached the
                      game of soccer, which led to a great career at Notre Dame,
                      and a National Championship in 2004.
                    </p>
                    <h3>Jill Kervecek</h3>
                    <span> Notre Dame</span>
                  </div>
                </div>
              </div>
              {/* </div> */}
            </div>

            <div className="item">
              {/* <div className="owl-item cloned"> */}
              <div className="">
                <div className="testi-box">
                  <div className="imgpic">
                    <img
                      src={barlowTesti}
                      className="img-fluid"
                      alt="winning ways"
                    />
                  </div>
                  <div className="img-cmnt">
                    <img
                      src={blkCmnt}
                      className="img-fluid img-testi"
                      alt="winning ways"
                    />
                  </div>

                  <div className="testi-cnt">
                    <p>
                      Winning Ways creates a game like experience in training
                      that helped prepare me for the MLS draft and ultimately
                      reach my dream of becoming a professional soccer player.
                      The program’s unique approach to individual and group
                      training challenged me to expand my game technically,
                      physically and mentally. I look forward to working with
                      Winning Ways again in the future!
                    </p>
                    <h3>Tom Barlow</h3>
                    <span> New York Red Bull</span>
                  </div>
                </div>
              </div>
              {/* </div> */}
            </div>
          </OwlCarousel>
        </div>
      </section>

      <Footer />

      {/* purchase subscription modal */}
      <Modal
        show={show}
        onHide={() => setShow(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body>
          <div id="subscribe" class="purchs-subs">
            <button type="button" class="close" onClick={() => setShow(false)}>
              &times;
            </button>
            <div class="subs text-center">
              <img src={logo} alt="" /> <br />
              <h3 class="prchs-subs">Purchasing Subscription</h3>
              {/* <h3 class="smr-trng">Summer Training Program</h3> */}
              <p class="offer">
                {" "}
                <span class="new-prc">${fixedprice} </span> for 1 Year
                subscription
              </p>
              {promeMessage ? (
                <span className="promoMessage">Promo Code Applied</span>
              ) : (
                ""
              )}
              {/* <p>Promo Code Applied</p> */}
              <form className="promo-code">
                <input
                  type="text"
                  placeholder="Enter Promo Code"
                  autoComplete="off"
                  value={promoCode}
                  onChange={(e) => setpromoCode(e.target.value)}
                />
                <button onClick={(e) => applypromocode(e)}>Apply</button>
              </form>
              <button
                type="submit"
                class="btn btn-purchase"
                onClick={(e) => openModal(e, "stripe")}
              >
                Purchase Now <i class="fa fa-angle-right"></i>
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* stripe modal */}
      <Modal
        show={showStripe}
        onHide={() => setShowStripe(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body>
          <Elements stripe={stripePromise}>
            <StripeForm
              setShowStripe={setShowStripe}
              setSubscriptionstartDate={setSubscriptionstartDate}
              setSubscriptionendDate={setSubscriptionendDate}
              fixedprice={fixedprice}
              convertedPromo={convertedPromo}
              setShowSuccessModal={setShowSuccessModal}
            />
          </Elements>
        </Modal.Body>
      </Modal>

      {/* pro user modal */}
      <Modal
        show={showProUser}
        onHide={() => setshowProUser(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body>
          <div id="thk-prchs" className="thnks-prchs">
            <button
              type="button"
              className="close"
              onClick={() => setshowProUser(false)}
            >
              &times;
            </button>
            <div className="subs text-center">
              <img src={logo} alt="" /> <br />
              <h3>Congratulations! You now have access to all Videos. </h3>
              <div className="aps d-flex">
                <p>Download the app from </p>{" "}
                <img src={downArrow} class="img-fluid" alt="winning ways" />
              </div>
              <div className="thnk-dwld-btns">
                <a
                  href="https://apps.apple.com/in/app/winning-ways-training/id1552675237"
                  rel="noreferrer"
                  target="_blank"
                >
                  {" "}
                  <img src={appStr} alt="" className="img-fluid" />{" "}
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.oit.wways&hl=en"
                  rel="noreferrer"
                  target="_blank"
                >
                  {" "}
                  <img
                    src={playStr}
                    alt=""
                    className="img-fluid img-ply-str"
                  />{" "}
                </a>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* success modal */}
      <Modal
        show={showSuccessModal}
        onHide={() => setShowSuccessModal(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body>
          <div id="thk-prchs" className="thnks-prchs">
            <button
              type="button"
              className="close"
              onClick={() => setShowSuccessModal(false)}
            >
              &times;
            </button>
            <div className="subs text-center">
              <img src={logo} alt="" /> <br />
              <h3>
                Your payment was successful.Thank you for purchasing 1 year
                membership.
              </h3>
              <div className="aps d-flex">
                <p>Download the app from </p>{" "}
                <img src={downArrow} class="img-fluid" alt="winning ways" />
              </div>
              <div className="thnk-dwld-btns">
                <a
                  href="https://apps.apple.com/in/app/winning-ways-training/id1552675237"
                  rel="noreferrer"
                  target="_blank"
                >
                  {" "}
                  <img src={appStr} alt="" className="img-fluid" />{" "}
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.oit.wways&hl=en"
                  rel="noreferrer"
                  target="_blank"
                >
                  {" "}
                  <img
                    src={playStr}
                    alt=""
                    className="img-fluid img-ply-str"
                  />{" "}
                </a>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* Already Purchase  modal */}
      <Modal
        show={showAlreadyPurchase}
        onHide={() => setShowAlreadyPurchase(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body>
          <div id="subscribe" class="purchs-subs">
            <button
              type="button"
              class="close"
              onClick={() => setShowAlreadyPurchase(false)}
            >
              &times;
            </button>
            <div class="subs text-center">
              <img src={logo} alt="" /> <br />
              <p class="cnt">
                You are already Subscribed for 1 Year subscription !
              </p>
              <div className="subs_dates d-flex">
                <p>
                  Start Date
                  <br />
                  <span>{subscriptionstartDate}</span>
                </p>
                <p>
                  End Date
                  <br /> <span>{subscriptionendDate}</span>
                </p>
              </div>
              <button
                className="btn btn-member cancel-sub"
                onClick={(e) => cancelMembership(e)}
                disabled={disableBtn}
              >
                Cancel Subscription
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <ToastContainer />
    </>
  );
}

export default Dashboard;
