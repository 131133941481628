import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import HeaderLogo from "../../assets/img/headerLogo.png";
// import 'bootstrap/dist/js/bootstrap.js';
import "bootstrap/dist/css/bootstrap.min.css";
import { Navbar, Nav, NavDropdown, Container } from "react-bootstrap";

import Auth from "../../Auth/Auth";
import { post, handleError, get } from "../../shared/HttpReq";
import { Toast } from "../../shared/toaster/Toaster";
import Spinner from "../../shared/spinner/Spinner";

import logo from "../../assets/img/logo.svg";
import Modal from "react-bootstrap/Modal";
import { useForm } from "../../shared/useForm";

import passwordUnView from "../../assets/img/icon_un_view.svg";
import passwordView from "../../assets/img/icon_view.svg";

function Header() {
  const [showLogin, setShowLogin] = useState(false);
  const [show, setShow] = useState(false);
  const [acceptTerm, updateAcceptTerm] = useState(false);
  const [passwordType, updatePasswordType] = useState(false);

  const userLogout = async (e) => {
    e.preventDefault();

    if (window.confirm("Do you want to logout?")) {
      setLoading(true);
      let res = await handleError(
        await fetch(
          `${process.env.REACT_APP_BASE_URL}/auth/logout`,
          await get()
        )
      );
      if (res.status === 200) {
        Auth.logout();
        route.push("/");
        SetToaster({
          show: true,
          type: "danger", // type should be "danger" or "success"
          content: res.message,
        });
      } else {
        SetToaster({
          show: true,
          type: "danger", // type should be "danger" or "success"
          content: res.message,
        });
      }
      setTimeout(() => {
        SetToaster({
          show: false,
          type: "",
          content: "",
        });
      }, 3000);
      setLoading(false);
    }
  };

  // TOASTER STATE
  const [toaster, SetToaster] = useState({
    show: false,
    type: "", // type should be "danger" or "success"
    content: "",
  });

  let { values, errors, bindField, isValid, setValues, setErrors } = useForm({
    validations: {
      email: {
        pattern: {
          value:
            /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
          message: "Invalid email address.",
        },
        required: {},
      },
      password: {
        minLength: {
          value: 8,
          message: "Password length should be 8 minimum.",
        },
        required: {},
      },
      name: {
        required: {},
      },
      // club_college: {
      // 	required: {},
      // },
      // state: {
      // 	required: {},
      // },
    },
  });

  const route = useHistory();
  const [isLoading, setLoading] = useState(false);

  // useEffect(() => {
  //     if (!Auth.isUserAuthenticated()) {
  //         route.push('/dashboard');
  //     }
  // }, []);

  const openModal = (e, type) => {
    updatePasswordType(false);
    e.preventDefault();
    setValues({});
    setErrors({});
    if (type === "login") {
      setShow(false);
      setShowLogin(true);
      return;
    }
    setShow(true);
    setShowLogin(false);
  };

  const handleAcceptTerm = (event) => {
    const target = event.target;
    const value = target.checked ? true : false;
    updateAcceptTerm(value);
  };

  const signupUser = async (e) => {
    e.preventDefault();
    if (!acceptTerm) {
      window.confirm("Please accept terms and conditions");
      return;
    }

    setLoading(true);
    const signupData = {
      ...values,
      ...{
        deviceType: "web",
        deviceToken: "",
        accept_term: acceptTerm ? 1 : 0,
      },
    };
    let res = await handleError(
      await fetch(
        `${process.env.REACT_APP_BASE_URL}/auth/registration`,
        await post(signupData)
      )
    );
    if (res.status === 200) {
      Auth.login(res);
      setShow(false);
      route.push("/dashboard");
      SetToaster({
        show: true,
        type: "danger", // type should be "danger" or "success"
        content: res.message,
      });
    } else if (res.status === 422) {
      console.log("error:", res);
      if (res.errors.email) {
        errors.email = res.errors.email;
      }
      if (res.errors.password) {
        errors.password = res.errors.password;
      }

      SetToaster({
        show: true,
        type: "danger", // type should be "danger" or "success"
        content: res.message,
      });

      setTimeout(() => {
        SetToaster({
          show: false,
          type: "",
          content: "",
        });
      }, 3000);
    } else {
      SetToaster({
        show: true,
        type: "danger", // type should be "danger" or "success"
        content: res.message,
      });

      setTimeout(() => {
        SetToaster({
          show: false,
          type: "",
          content: "",
        });
      }, 3000);
    }

    setLoading(false);
  };

  const signInUser = async (e) => {
    e.preventDefault();
    setLoading(true);
    const signInData = { ...values, ...{ deviceType: "web", deviceToken: "" } };
    let res = await handleError(
      await fetch(
        `${process.env.REACT_APP_BASE_URL}/auth/login`,
        await post(signInData)
      )
    );
    if (res.status === 200) {
      Auth.login(res);
      setShow(false);
      route.push("/dashboard");
      SetToaster({
        show: true,
        type: "danger", // type should be "danger" or "success"
        content: res.message,
      });
    } else if (res.status === 422) {
      console.log("error:", res);
      if (res.errors.email) {
        errors.email = res.errors.email;
      }
      if (res.errors.password) {
        errors.password = res.errors.password;
      }

      SetToaster({
        show: true,
        type: "danger", // type should be "danger" or "success"
        content: res.message,
      });

      setTimeout(() => {
        SetToaster({
          show: false,
          type: "",
          content: "",
        });
      }, 3000);
    } else {
      SetToaster({
        show: true,
        type: "danger", // type should be "danger" or "success"
        content: res.message,
      });

      setTimeout(() => {
        SetToaster({
          show: false,
          type: "",
          content: "",
        });
      }, 3000);
    }

    setLoading(false);
  };
  return (
    <>
      {isLoading ? <Spinner /> : null}
      <Toast
        show={toaster.show}
        content={toaster.content}
        type={toaster.type}
        onClose={() => {
          SetToaster({
            show: false,
            type: "",
            content: "",
          });
        }}
      />
      <div className="containerd">
        <Navbar expand="lg">
          <Container>
            <Navbar.Brand>
              <Link to="/">
                <img src={HeaderLogo} alt="" />
              </Link>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                <Nav.Link>
                  <Link to="/"> Home </Link>
                </Nav.Link>

                <NavDropdown title="About Us" id="basic-nav-dropdown">
                  <NavDropdown.Item>
                    <Link to="/ourTeam">Our Team</Link>
                  </NavDropdown.Item>
                  <NavDropdown.Item>
                    <Link to="/OurPhilosophy">Our Philosophy</Link>
                  </NavDropdown.Item>
                  <NavDropdown.Item>
                    <Link to="/OurStory">Our Story</Link>
                  </NavDropdown.Item>
                </NavDropdown>
                <Nav.Link>
                  <Link to="/Membership">Membership</Link>
                </Nav.Link>
                <Nav.Link>
                  <Link
                    to
                    onClick={() =>
                      window.open(
                        "https://winningwaystraining.com/Winningways/product-category/camps/"
                      )
                    }
                  >
                    Camps
                  </Link>
                </Nav.Link>
                <Nav.Link>
                  <Link
                    to
                    onClick={() =>
                      window.open(
                        "https://winningwaystraining.com/Winningways/product-category/shop/"
                      )
                    }
                  >
                    Shop
                  </Link>
                </Nav.Link>
                <Nav.Link>
                  <Link to="/Contactus"> Contact Us</Link>
                </Nav.Link>
              </Nav>
              {Auth.isUserAuthenticated() ? (
                <div className="btn-register-area">
                  <button className="btn " onClick={userLogout}>
                    Logout <i className="fa fa-angle-right"></i>
                  </button>
                </div>
              ) : (
                <div className="btn-register-area">
                  <button
                    className="btn "
                    onClick={(e) => openModal(e, "signup")}
                  >
                    Register <i className="fa fa-angle-right"></i>
                  </button>
                </div>
              )}
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
      {/* login modal */}
      <Modal
        show={showLogin}
        onHide={() => setShowLogin(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body>
          <div className="login-form" id="login">
            <button
              type="button"
              className="close"
              onClick={() => setShowLogin(false)}
            >
              &times;
            </button>
            <div className="reg text-center">
              <img src={logo} alt="" /> <br />
              <h3>Login</h3>
            </div>
            <form onSubmit={signInUser}>
              <div className="">
                <label> Email </label>
                <input
                  type="text"
                  name="email"
                  placeholder="Enter Email"
                  className="reg-frm"
                  {...bindField("email")}
                />
                {errors.email && <p className="text-danger">{errors.email}</p>}
              </div>
              <div className="position-relative">
                <label> Password</label>
                <input
                  type={passwordType ? "text" : "password"}
                  name="password"
                  placeholder="Enter Password"
                  className="reg-frm padRight"
                  {...bindField("password")}
                />

                <span className="viewIcon">
                  <img
                    className="cursor-pointer"
                    src={passwordType ? passwordView : passwordUnView}
                    alt=""
                    onClick={() => updatePasswordType(!passwordType)}
                  />
                </span>
              </div>
              {errors.password && (
                <p className="text-danger">{errors.password}</p>
              )}

              <button type="submit" className="btn btn-login">
                Login <i className="fa fa-angle-right"></i>
              </button>
            </form>
            <p className="mt-2">
              Don’t have an account?
              <span
                className="cursor-pointer"
                onClick={(e) => openModal(e, "signup")}
              >
                {" "}
                Register Now
              </span>
            </p>
          </div>
        </Modal.Body>
      </Modal>

      {/* <!--login form start--> */}

      {/* <!--login form end--> */}

      {/* signup modal*/}
      <Modal
        show={show}
        onHide={() => setShow(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body>
          <div className="register-form">
            <button
              type="button"
              className="close"
              onClick={() => setShow(false)}
            >
              x
            </button>
            <div className="reg text-center">
              <img src={logo} alt="" /> <br />
              <h3>Register Now</h3>
            </div>
            <form onSubmit={signupUser} className="mt-3">
              <div className="">
                <label className="">Name</label>
                <input
                  type="text"
                  name="name"
                  placeholder="Enter Name"
                  className="reg-frm"
                  {...bindField("name")}
                />
                {errors.name && (
                  <p className="text-danger mb-0">{errors.name}</p>
                )}
              </div>
              <div className="">
                <label className=""> Email </label>
                <input
                  type="text"
                  name="email"
                  placeholder="Enter Email"
                  className="reg-frm"
                  {...bindField("email")}
                />
                {errors.email && (
                  <p className="text-danger mb-0">{errors.email}</p>
                )}
              </div>
              <div className="position-relative">
                <label className=""> Password</label>
                <input
                  type={passwordType ? "text" : "password"}
                  name="password"
                  placeholder="Enter Password"
                  className="reg-frm padRight"
                  {...bindField("password")}
                />

                <span className="viewIcon">
                  <img
                    className="cursor-pointer"
                    src={passwordType ? passwordView : passwordUnView}
                    alt=""
                    onClick={() => updatePasswordType(!passwordType)}
                  />
                </span>
              </div>
              {errors.password && (
                <p className="text-danger mb-0">{errors.password}</p>
              )}
              <div className="">
                <label className=""> Club/College</label>
                <input
                  type="text"
                  name="club"
                  placeholder="Enter Club/College"
                  className="reg-frm"
                  {...bindField("club_college")}
                />
                {errors.club_college && (
                  <p className="text-danger mb-0">{errors.club_college}</p>
                )}
              </div>
              <div className="">
                <label className="">State </label>
                <input
                  type="text"
                  name="text"
                  placeholder="Enter State"
                  className="reg-frm"
                  {...bindField("state")}
                />
                {errors.state && (
                  <p className="text-danger mb-0">{errors.state}</p>
                )}
              </div>
              {/* <div className="">
                                <label className="">Referral Code (Optional)</label>
                                <input type="text" name="text" placeholder="Enter Referral Code" className="reg-frm" {...bindField('code')} />
                                {errors.code && <p className="text-danger mb-0">{errors.code}</p>}
                            </div> */}

              <br />
              <input
                type="checkbox"
                name="terms"
                id="terms"
                checked={acceptTerm === true}
                onChange={handleAcceptTerm}
              />
              <span className="acpt"> Accept our </span>
              <span
                className="cursor-pointer"
                onClick={() =>
                  window.open(
                    "https://winningwaystrainingapp.com/terms.html",
                    "Term"
                  )
                }
              >
                Terms & Conditions
              </span>
              <br />
              <br />
              <button
                type="submit"
                className="btn btn-register"
                disabled={!isValid()}
              >
                Register <i className="fa fa-angle-right"></i>
              </button>
            </form>
            <p className="mt-2">
              Already a member?{" "}
              <span>
                <span
                  className="cursor-pointer"
                  onClick={(e) => openModal(e, "login")}
                >
                  Sign In
                </span>
              </span>
            </p>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Header;
