import React, { useEffect } from 'react'
import Header from '../../../shared/header/Header'
import Footer from '../../../shared/footer/Fotter'
import RobertIMG from '../../../assets/img/Robert-Team.png'
import RobkehoeIMG from '../../../assets/img/RobKehoe.png'
import SteveMyrlandIMG from '../../../assets/img/SteveMyrland.png'
import HannahDavisonIMG from '../../../assets/img/HannahDavison.png'
import DrewConnerIMG from '../../../assets/img/DrewConner.png'
import MickSample from '../../../assets/img/Mick-Sample.jpg'
import RyanMahoneyIMG from '../../../assets/img/RyanMahoney.png'
import { Link } from 'react-router-dom'
import SingleMember from './SingleMember'

function OurTeam() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <section className="sec-1 our-team-main">
                <div className="container-fluid">
                    <Header />
                    {/* <div className="d-flex justify-content-end hd-regs">
                        <button className="btn btn-member registerBtn ">Register <i className="fa fa-angle-right"></i></button>
                    </div> */}

                    <div className="row container custm-pad">
                        {/* <img src={SliderIMG1} alt="" /> */}
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0">
                            <div className="sec1-lt">
                                <h2>Our Team</h2>
                                <div className="link_nav_con">
                                    <ul className="d-flex align-items-center">
                                        <li><Link to="/">Home</Link></li>
                                        <li><Link to="/">About Us</Link></li>
                                        <li><a className="active_hero">Our Team</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
							<div className="sec1-rt"> <img src={SliderIMG1} alt="" className="img-fluid" /> </div>
						</div> */}
                    </div>
                </div>
            </section>
            <section className="team-members">
                <div className="hd">
                    <h3 className="sec-hd text-center">
                        Meet Our Team
                    </h3>
                </div>
                <div className="team-items container">

                    <div className="row">
                        <div className="col-4">
                            <div className="card">
                                <img className="card-img-top" src={RobertIMG} alt="Team Member Image" />
                                <div className="card-body">
                                    <h5 className="card-title">Robert L. Kehoe III</h5>
                                    <p className="card-text">Founding Partner/CEO</p>
                                    <Link to="/SingleMember" className="btn">View Detail</Link>
                                    <i class="fa fa-angle-right"></i>
                                </div>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="card">
                                <img className="card-img-top" src={RobkehoeIMG} alt="Team Member Image" />
                                <div className="card-body">
                                    <h5 className="card-title">Rob Kehoe Jr. </h5>
                                    <p className="card-text">Founding Partner/President</p>
                                    <Link to="/RobKehoe" className="btn">View Detail</Link>
                                    <i class="fa fa-angle-right"></i>
                                </div>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="card">
                                <img className="card-img-top" src={SteveMyrlandIMG} alt="Team Member Image" />
                                <div className="card-body">
                                    <h5 className="card-title">Steve Myrland</h5>
                                    <p className="card-text">Founding Partner/Senior Advisor</p>
                                    <Link to="/SteveMyrland" className="btn">View Detail</Link>
                                    <i class="fa fa-angle-right"></i>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-4">
                            <div className="card">
                                <img className="card-img-top" src={DrewConnerIMG} alt="Team Member Image" />
                                <div className="card-body">
                                    <h5 className="card-title">Drew Conner</h5>
                                    <p className="card-text">Director of Player Engagement and Communications</p>
                                    <Link to="/DrewConner" className="btn">View Detail</Link>
                                    <i class="fa fa-angle-right"></i>
                                </div>
                            </div>
                        </div>

                        <div className="col-4">
                            <div className="card">
                                <img className="card-img-top" src={MickSample} alt="Team Member Image" />
                                <div className="card-body">
                                    <h5 className="card-title">Mick Maley</h5>
                                    <p className="card-text">Director of Operations </p>
                                    <Link to="/MickMaley" className="btn">View Detail</Link>
                                    <i class="fa fa-angle-right"></i>
                                </div>
                            </div>
                        </div>

                    </div>




                </div>
            </section>
            <section class="membrshp text-center">
                <h3 class="sec-hd">Membership</h3>
                <p class="cnt">Become a member today to gain exclusive access to our training video library.</p>
                <Link to="/Membership">  <button class="btn btn-member">Subscribe Membership <i class="fa fa-angle-right"></i></button></Link>
            </section>
            <Footer />
        </>
    )
}

export default OurTeam
